import { alpha } from "@material-ui/core/styles";
const styles = (theme) => ({

  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(10),
    },
  },
  section: {
    marginTop: -10,
    marginBottom: -10,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // [theme.breakpoints.up("md")]: {
    //   paddingBottom: theme.spacing(4),

    // },
  },
  waveDiv: {
    position: "relative",

    pointerEvents: "none",
  },
});

export default styles;
