const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },

  map: {
    border: 0,
  },
  cardContent: {
    color: theme.palette.common.black,
    padding: theme.spacing(2),
    alignItems: "center",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  title: {
    padding: theme.spacing(2),
  },
  gridImg: {
    flexBasis: "0%",
  },
  warning: {
    paddingTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
});

export default styles;
