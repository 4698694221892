import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withPrefix, useStaticQuery, graphql } from "gatsby";
function SEO({ description, meta, image, title, location }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            ogImage
            siteUrl
          }
        }
      }
    `
  );

  const url = site.siteMetadata.siteUrl;
  // const url = "https://www.codeacademyberlin.com";
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = `${url}/${site.siteMetadata.ogImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: site.siteMetadata.keywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: location.href,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:author`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ]
        .concat([
          {
            property: "og:image",
            content: metaImage,
          },
        ])
        .concat(meta)}
    >
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${withPrefix("/")}assets/logo_no_text.png`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix("/")}assets/logo_no_text.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix("/")}assets/logo_no_text.png`}
        sizes="16x16"
      />
      <link
        rel="mask-icon"
        href={`${withPrefix("/")}assets/A-logo-primary.svg`}
        color="#ff4400"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string,
};

export default SEO;
