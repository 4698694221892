import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Container } from "@material-ui/core";

const ContentCards = ({ content, noPic, classes }) => {
  const theme = useTheme();
  return (
    <Container
      maxWidth="lg"

      className={classes.container}>

      {content.blurbs.map((blurb, i) => (

        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
          key={blurb.title}
          className={classes.cardContent}
        >

          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className={classes.imageContainer}
          >
            <PreviewCompatibleImage
              className={classes.image}
              imageInfo={blurb}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={9}
            container
            justifyContent="flex-start"
            alignItems="center"

          >

            <Typography
              component="h3"
              variant="h3"
              color="inherit"
              align="center"

            >
              {blurb.title}
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              align="justify"

            >
              {blurb.text}
            </Typography>
            {blurb.blurbs && <ul className={classes.list} >
              {blurb.blurbs.map((oneBlurb) => (
                <React.Fragment
                  key={oneBlurb.title}>
                  <Typography
                    type="li"
                    variant="h6"

                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {oneBlurb.title}{" "}
                    </span>
                    "{oneBlurb.content}"
                  </Typography>
                </React.Fragment>))}

            </ul>}
          </Grid>
        </Grid>
      ))
      }
    </Container >
  );
};
// const ContentCards = ({ content, isRed, noPic, classes }) => {
//   isRed ? (isRed = true) : (isRed = false);
//   const theme = useTheme();
//   const smDown = useMediaQuery(theme.breakpoints.down("sm"));
//   return (
//     <React.Fragment>
//       {!smDown
//         ? content.blurbs.map((blurb, i) => (
//           <React.Fragment key={blurb.title}>
//             {i % 2 === 0 ? (
//               <Grid
//                 container
//                 spacing={6}
//                 className={classes.container}
//                 style={
//                   noPic
//                     ? {
//                       display: "flex",
//                       justifyContent: "center",
//                       // marginTop: theme.spacing(2),
//                     }
//                     : {}
//                 }
//               >
//                 {!noPic && (
//                   <Grid item
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     md={12} lg={3} className={classes.image}>
//                     {!noPic && (<Img fixed={blurb.image.childImageSharp.fixed} />)}
//                     {/* <PreviewCompatibleImage
//                       className={classes.image}
//                       imageInfo={blurb}
//                     /> */}
//                   </Grid>
//                 )}
//                 <Grid
//                   item
//                   md={noPic ? 12 : 9}
//                   className={classes.cardContent}
//                 >
//                   <Typography
//                     component="h4"
//                     variant="h4"
//                     className={classes.title}
//                     align="center"
//                     style={
//                       isRed
//                         ? { color: theme.palette.secondary.main }
//                         : { color: "inherit" }
//                     }
//                   >
//                     {blurb.title}
//                   </Typography>
//                   <Typography
//                     component="h6"
//                     variant="h6"
//                     align="justify"
//                     style={{
//                       marginTop: theme.spacing(0),
//                       // textAlign: "justify",
//                     }}
//                   >
//                     {blurb.text}
//                   </Typography>
//                   {blurb.blurbs &&
//                     blurb.blurbs.map((oneBlurb) => (
//                       <React.Fragment key={oneBlurb.title}>
//                         <Typography
//                           type="h6"
//                           variant="h6"
//                           style={{ marginTop: theme.spacing(2) }}
//                         >
//                           <span
//                             style={{
//                               fontWeight: "bold",
//                               color: theme.palette.secondary.main,
//                             }}
//                           >
//                             {oneBlurb.title}{" "}
//                           </span>
//                           "{oneBlurb.content}"
//                         </Typography>
//                       </React.Fragment>
//                     ))}
//                 </Grid>
//               </Grid>
//             ) : (
//               <Grid
//                 container
//                 spacing={6}
//                 className={classes.container}
//                 style={
//                   noPic ? { display: "flex", justifyContent: "flex-end" } : {}
//                 }
//               >
//                 <Grid
//                   item
//                   md={noPic ? 12 : 9}
//                   className={classes.cardContent}
//                 >
//                   <Typography
//                     component="h4"
//                     variant="h4"
//                     className={classes.title}
//                     align="center"
//                     style={
//                       isRed
//                         ? { color: theme.palette.secondary.main }
//                         : { color: "inherit" }
//                     }
//                   >
//                     {blurb.title}
//                   </Typography>
//                   <Typography
//                     component="h6"
//                     variant="h6"
//                     align="justify"
//                     style={{
//                       marginBottom: theme.spacing(0),
//                       // textAlign: "justify",
//                     }}
//                   >
//                     {blurb.text}
//                   </Typography>
//                   {blurb.blurbs &&
//                     blurb.blurbs.map((oneBlurb) => (
//                       <React.Fragment key={oneBlurb.title}>
//                         <Typography
//                           type="h6"
//                           variant="h6"
//                           style={{ marginTop: theme.spacing(2) }}
//                         >
//                           <span
//                             style={{
//                               fontWeight: "bold",
//                               color: theme.palette.secondary.main,
//                             }}
//                           >
//                             {oneBlurb.title}{" "}
//                           </span>
//                           "{oneBlurb.content}"
//                         </Typography>
//                       </React.Fragment>
//                     ))}{" "}
//                 </Grid>
//                 {!noPic && (
//                   <Grid item
//                     container
//                     justifyContent="center"
//                     alignItems="center"
//                     md={12} lg={3} className={classes.image}>
//                     {!noPic && (<Img fixed={blurb.image.childImageSharp.fixed} />)}
//                     {/* <PreviewCompatibleImage
//                       className={classes.image}
//                       imageInfo={blurb}
//                     /> */}
//                   </Grid>
//                 )}
//               </Grid>
//             )}
//           </React.Fragment>
//         ))
//         : content.blurbs.map((blurb, i) => (
//           <Grid
//             container
//             spacing={3}
//             justifyContent="center"

//             className={classes.containerMobile}
//             style={
//               noPic
//                 ? {
//                   display: "flex",
//                   justifyContent: "center",
//                   padding: theme.spacing(0),
//                 }
//                 : {}
//             }
//           >
//             <Typography
//               component="h4"
//               variant="h4"
//               className={classes.titleMobile}
//               align="center"
//               style={
//                 isRed
//                   ? { color: theme.palette.secondary.main }
//                   : { color: "inherit" }
//               }
//             >
//               {blurb.title}
//             </Typography>
//             {!noPic && (
//               <Grid
//                 item
//                 md={6}
//                 className={classes.image}
//               // style={{ paddingLeft: theme.spacing(0) }}
//               >
//                 <PreviewCompatibleImage
//                   className={classes.image}
//                   imageInfo={blurb}
//                 />
//               </Grid>
//             )}
//             <Grid item md={noPic ? 12 : 6} className={classes.cardContent}>
//               <Typography
//                 component="h6"
//                 variant="h6"
//                 align="justify"
//                 style={{
//                   marginBottom: theme.spacing(0),
//                   // textAlign: "justify",
//                 }}
//               >
//                 {blurb.text}
//               </Typography>
//               {blurb.blurbs &&
//                 blurb.blurbs.map((oneBlurb) => (
//                   <React.Fragment key={oneBlurb.title}>
//                     <Typography
//                       type="h6"
//                       variant="h6"
//                       style={{ marginTop: theme.spacing(2) }}
//                     >
//                       <span
//                         style={{
//                           fontWeight: "bold",
//                           color: theme.palette.secondary.main,
//                         }}
//                       >
//                         {oneBlurb.title}{" "}
//                       </span>
//                       "{oneBlurb.content}"
//                     </Typography>
//                   </React.Fragment>
//                 ))}
//             </Grid>
//           </Grid>
//         ))}
//     </React.Fragment>
//   );
// };
export default withStyles(styles)(ContentCards);
