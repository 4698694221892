import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import styles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "gatsby";
const HeaderSection = ({ header, subheader, classes, link, align = "center" }) => {


  return (
    <Container className={classes.headerContent}>

      <Typography
        component="h2"
        variant="h2"
        align="center"
        color="inherit"
        gutterBottom
      >
        {header}
      </Typography>
      {subheader && (
        <Typography
          variant="h4"
          align={align}
          color="inherit"
          component="h4"
        >
          {subheader}{" "}
          {link && (
            <Link to={link.to}>
              {link.text}
            </Link>
          )}
        </Typography>
      )}
    </Container>
  );
};
HeaderSection.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  classes: PropTypes.shape({}),
};

export default withStyles(styles)(HeaderSection);
