const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(8),

  },

  cardContent: {
    marginTop: theme.spacing(6),
    // flexGrow: 2,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "baseline",
    // justifyContent: "center",
  },
  imageContainer: {
    paddingBottom: theme.spacing(0),
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 300,

    },
  },
  image: {
    // flexGrow: 1,
    // maxWidth: 100,

  },
  title: {
    color: theme.palette.secondary.main
  },

  list: {
    padding: theme.spacing(0),

  },
});
export default styles;
