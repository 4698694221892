const styles = (theme) => ({
  headerContent: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),


    // [theme.breakpoints.up("md")]: {
    //   paddingTop: theme.spacing(4),
    //   paddingBottom: theme.spacing(4),
    //   marginBottom: theme.spacing(0),
    // },
  },
});
export default styles;
