import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import ContentCards from "../components/ContentCardsCampus";
import CampusLocation from "../components/CampusLocation";
import HeaderSection from "../components/HeaderSection";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";
import SectionDecoration from "../components/SectionDecoration";
import ContentJustified from "../components/ContentJustified";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SEO from "../components/SEO";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import BookingModal from "../components/BookingModal/BookingModal";
import { Button } from "@material-ui/core";


const CampusPageTemplate = ({
  title,
  heading,
  description,
  intro,
  campus,
  content,
  officeLocation,
  location,
}) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>

      <Container maxWidth="lg">
        <HeaderSection
          header={heading}
          subheader={description}
          location={location}
        />
        {/* <PreviewCompatibleImage
          imageInfo={{
            image: image,
            alt: ` ${title}`,
          }}
        /> */}

        {/* <ContentJustified content={content} /> */}

      </Container>
      <SectionDecoration
        noTop={false}
        color={theme.palette.common.white}
        bgColor={theme.palette.primary.main}
      >
        <Container
          maxWidth="lg"
          style={{
            padding: 0,
            paddingTop: theme.spacing(8),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >

          <ContentCards content={intro} noPic={false} />
        </Container>
      </SectionDecoration>
      <Container maxWidth="lg" style={{
        padding: 0,
        paddingTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <HeaderSection
          header="Come checkout the campus vibe yourself"
          subheader="You can select a campus visit when scheduling a meeting with an advisor"
          location={location}
        />
        <Button
          variant="contained"
          component={Link}
          color="primary"
          to="/advisors"
        >Talk to our advisors</Button>
        {/* <BookingModal variant="contained" /> */}
        <CampusLocation officeLocation={officeLocation} />
      </Container>
    </React.Fragment>
  );
};

CampusPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  content: PropTypes.node.isRequired,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  campus: PropTypes.shape({}),
  officeLocation: PropTypes.shape({}),
};

const CampusPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={"Coding bootcamp in Berlin"}
      />
      <CampusPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        content={html}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        campus={frontmatter.campus}
        officeLocation={frontmatter.officeLocation}
        location={location}
      />
    </Layout>
  );
};

CampusPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CampusPage;
export const campusPageQuery = graphql`
  query CampusPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                 fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
              }
            }
            title
            text
          }
        }
        officeLocation {
          title
          text
          address
        }
      }
    }
  }
`;
