import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const CampusLocation = ({ officeLocation, classes }) => {
  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item sm={12} md={6}>
          <div className={classes.cardContent}>
            <Typography component="h3" variant="h3">
              {officeLocation.title}
            </Typography>
            <Typography
              component="h5"
              variant="h5"
              align="justify"
              gutterBottom
            >
              {officeLocation.text}
            </Typography>
            <Typography component="h6" variant="h6">
              {officeLocation.address}
            </Typography>
          </div>
        </Grid>

        <Grid item sm={12} md={6}>
          <iframe
            title="Code Academy Berlin Campus location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2426.3977035319913!2d13.438591515698066!3d52.54433067981881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84f446e0c53a1%3A0x27eac7eee50f967e!2sCode%20Academy%20Berlin!5e0!3m2!1sit!2sde!4v1643192992284!5m2!1sen!2sde"
            width="100%"
            height="300px"
            frameBorder="0"
            className={classes.map}
            allowFullScreen=""
            aria-hidden="false"
          ></iframe>
        </Grid>
      </Grid>
      {/* <Typography component="h5" variant="h5" className={classes.warning}>
        We just moved, so if you search for us directly on Google Maps you will
        find us with the old address!
      </Typography> */}
    </React.Fragment>
  );
};
export default withStyles(styles)(CampusLocation);
