import React from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import HeroSVG from "../../../public/assets/heroWave.svg";
import WaveDown from "../../../public/assets/waveDown.svg";
import styles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SectionDecoration = ({ children, color, bgColor, classes, noTop }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  // const showTopSvg = !noTop || !mdDown
  return (
    <div className={classes.sectionContainer}>
      {/* {!mdDown && ( */}
      <div className={classes.waveDiv}>
        <HeroSVG
          fill={bgColor}
          height={mdDown ? 100 : "100%"}
          transform="scale(-1,1)"
        // style={{ marginTop: -60 }}
        />
      </div>
      {/* )} */}
      <div
        className={classes.section}
        style={{ color: color, backgroundColor: bgColor }}
      >
        {children}
      </div>
      {/* {!mdDown && ( */}
      <div className={classes.waveDiv}>
        <WaveDown fill={bgColor} height={mdDown ? 100 : "100%"} />
      </div>
      {/* )} */}
    </div>
  );
};

SectionDecoration.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  classes: PropTypes.shape({}),
  noTop: PropTypes.bool,
};

export default withStyles(styles)(SectionDecoration);
